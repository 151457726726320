$base-font-family: 'Rubik', sans-serif;
$base-font-size: 1.6rem;
$base-line-height: 1.5;
$base-font-weight: 400;

$color-text: #15171a;
$color-background: #fff;
$color-details: #ababab;

$container-width: 1600px;
$spacing-unit: 20px;
$on-palm: 600px;
$on-laptop: 800px;

@mixin media($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@import "fonts",
        "base",
        "layout";
