@font-face {
  font-family: 'Rubik';
  src: url('/assets/fonts/hinted-Rubik-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('/assets/fonts/hinted-Rubik-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('/assets/fonts/hinted-Rubik-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('/assets/fonts/hinted-Rubik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('/assets/fonts/hinted-Rubik-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('/assets/fonts/hinted-Rubik-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('/assets/fonts/hinted-Rubik-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('/assets/fonts/hinted-Rubik-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
